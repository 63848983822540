export const ENDPOINTS = {
  AUTH_LOGIN: "auth/login",
  AUTH_LOGOUT: "auth/logout",
  AUTH_ME: "auth/me",

  EMPLOYEE_SEARCH: "employee/search",
  EMPLOYEE_SHOW: "employee/show",
  EMPLOYEE_EDIT: "employee/edit",
  EMPLOYEE_IMPORT: "employee/import",
  EMPLOYEE_LIST: "employee/list",

  PERMISSION_LIST_ROLE: "permission/list-role",

  SALE_FORCE_LIST: "sale-force/list",
  SALE_FORCE_SEARCH: "sale-force/search",
  SALE_FORCE_SHOW: "sale-force/show",
  SALE_FORCE_UPDATE_PRODUCTS: "sale-force/update-products",

  ANATOMY_PHYSIOLOGY_LIST: "anatomy-physiology/list",
  ANATOMY_PHYSIOLOGY_SEARCH: "anatomy-physiology/search",
  ANATOMY_PHYSIOLOGY_STORE: "anatomy-physiology/store",
  ANATOMY_PHYSIOLOGY_SHOW: "anatomy-physiology/show",
  ANATOMY_PHYSIOLOGY_EDIT: "anatomy-physiology/edit",
  ANATOMY_PHYSIOLOGY_DELETE: "anatomy-physiology/delete",

  PATHOLOGY_LIST: "pathology/list",
  PATHOLOGY_SEARCH: "pathology/search",
  PATHOLOGY_STORE: "pathology/store",
  PATHOLOGY_SHOW: "pathology/show",
  PATHOLOGY_EDIT: "pathology/edit",
  PATHOLOGY_DELETE: "pathology/delete",

  SPECIALTY_LIST: "specialty/list",
  SPECIALTY_SEARCH: "specialty/search",
  SPECIALTY_STORE: "specialty/store",
  SPECIALTY_SHOW: "specialty/show",
  SPECIALTY_EDIT: "specialty/edit",
  SPECIALTY_DELETE: "specialty/delete",

  LABORATORY_LIST: "laboratory/list",
  LABORATORY_SEARCH: "laboratory/search",
  LABORATORY_STORE: "laboratory/store",
  LABORATORY_SHOW: "laboratory/show",
  LABORATORY_EDIT: "laboratory/edit",
  LABORATORY_DELETE: "laboratory/delete",

  LINE_LIST: "line/list",
  LINE_SEARCH: "line/search",
  LINE_STORE: "line/store",
  LINE_SHOW: "line/show",
  LINE_EDIT: "line/edit",
  LINE_DELETE: "line/delete",

  CLASSIFICATION_LIST: "classification/list",
  CLASSIFICATION_SEARCH: "classification/search",
  CLASSIFICATION_STORE: "classification/store",
  CLASSIFICATION_SHOW: "classification/show",
  CLASSIFICATION_EDIT: "classification/edit",
  CLASSIFICATION_DELETE: "classification/delete",

  SECTOR_LIST: "sector/list",

  DISTRICT_LIST: "district/list",

  REGIONAL_LIST: "regional/list",

  PRODUCT_LIST: "product/list",
  PRODUCT_SEARCH: "product/search",
  PRODUCT_SHOW: "product/show",
  PRODUCT_DELETE: "product/delete",
  PRODUCT_STORE: "product/store",
  PRODUCT_EDIT: "product/edit",

  NOTIFICATION_LIST: "notification/list",
  NOTIFICATION_SEARCH: "notification/search",
  NOTIFICATION_SHOW: "notification/show",
  NOTIFICATION_DELETE: "notification/delete",
  NOTIFICATION_STORE: "notification/store",
  NOTIFICATION_EDIT: "notification/edit",
  NOTIFICATION_SEND: "notification/send",

  STATISTIC_PRODUCT_TIMES: "statistic-product/times",
  STATISTIC_PRODUCT_VIEWS: "statistic-product/views",

  STATISTIC_TERM_QUANTITATIVE: "statistic-term/quantitative",
};
